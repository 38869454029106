import React from "react";

import bannerbreadcrumbs from "../../../assets/images/header/banner-breadcumbs.jpg";

export default function Breadcrumbsbanner() {
  return (
    <>
      <div className="breadcrumbsbanner-wrapper inner-pages">
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <img
            className="img-fluid"
            src={bannerbreadcrumbs}
            width="100%"
            alt="Clock logo"
          />
        </div>
      </div>
    </>
  );
}
