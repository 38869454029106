import React from "react";
import Seo from "../components/seo/index";
import Layout from "../layout/index";
import Buttonevents  from "../components/raceinfo/buttonevents";
import Racebreadcrumbpages from "../components/raceinfo/racebreadcrumb";

import RacePlaces from "../components/sharedComponents/race-places/racePlaces";

export default function RaceLocations() {
  const navigatetopage=(data)=>{
    console.log(data);
  }
  return (
    <>
      <div>
        <Seo title="Green Heartfulness Run 2024 - New Registration" />
        <Layout isSticky>
        <div>
        <Buttonevents  navigatetopage={navigatetopage}/>
        <Racebreadcrumbpages />
         <RacePlaces />
        </div>
        </Layout>
      </div>
    </>
  );
}
